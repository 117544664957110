<script setup>
    import helper from '~/resources/js/libraries/helper';
    import { useHomeStore } from '~/stores/home.store';

    const homeStore = useHomeStore();
    const data = ref([]);
    data.value = await homeStore.fetchDiscountListings();
    const currency = helper.cookies.currency();

</script>
<template>
    <section v-if="data?.length">
        <div class="container mx-auto my-10 max-w-7xl px-4 sm:px-6 lg:px-8">
            <div class="text-center mb-10 text-indigo-900 text-2xl" v-html="$t('client.listings_discount')"></div>
            <w-carousel-multi :isHaveSlot="true" :items="data" arrows>
                <template #item="{item}">
                    <div class="relative grid grid-cols-1 mx-3 w-72" itemscope itemtype="https://schema.org/RealEstateListing">
                            <NuxtLink :to="localePath({ name: 'listings.show', params: { slug: item.slug } })" itemprop="url">
                                <div class="flex justify-center absolute w-full top-3">
                                    <div class="text-center text-neutral-400 text-xs font-normal leading-none px-3 py-2 rounded bg-white">Discount</div>
                                </div>
                                <img loading="lazy" class="w-full h-56 rounded-tl-2xl rounded-tr-2xl object-cover"
                                    :src="`${item.picture}?w=299&h=224`" :alt="item.title" itemprop="thumbnailUrl" />
                                <div
                                    class="px-12 py-2.5 bg-white rounded-bl-2xl rounded-br-2xl flex-col items-center gap-2.5 flex">
                                    <div class="text-center text-neutral-400 text-xs font-normal leading-none truncate">
                                        {{ item.cities.join(' / ') }}
                                    </div>
                                    <div class="text-center text-indigo-900 text-xl font-bold leading-normal">
                                        {{ helper.formatPrice(item.list_price) }}
                                        {{ currency.symbol }}
                                    </div>
                                    <div class="text-center text-neutral-400 text-base font-normal"
                                        style="background: linear-gradient(to left top, transparent 47.75%, currentColor 49.5%, currentColor 50.5%, transparent 52.25%)">
                                        {{ helper.formatPrice(item.sale_price) }}
                                        {{ currency.symbol }}
                                    </div>
                                </div>
                            </NuxtLink>
                    </div>
                </template>
            </w-carousel-multi>
        </div>
    </section>
</template>
